var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('span',{staticClass:"text-h5"},[_vm._v(" Logs ")])])],1),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.list(true)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-card',[_c('v-card-title',{staticClass:"card-title"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-magnify")]),_vm._v(" Filtro")],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:300ms",value:(_vm.list),expression:"list",arg:"300ms"}],attrs:{"label":"Texto"},model:{value:(_vm.logList.filter.text),callback:function ($$v) {_vm.$set(_vm.logList.filter, "text", $$v)},expression:"logList.filter.text"}})],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-card',[_c('v-card-text',[_c('Paginator',_vm._b({attrs:{"showRefresh":true},on:{"page":_vm.pageSelected,"refresh":function($event){return _vm.list(true)}}},'Paginator',_vm.logList.pagingOptions,false,true)),_c('ButtonBar',[(_vm.logList.data.length)?_c('v-btn',{attrs:{"type":"button","color":"primary","loading":_vm.solveButton.loading,"disabled":!_vm.logList.selectedLogs.length},on:{"click":_vm.markAsSolved}},[_vm._v("Marcar como resolvido")]):_vm._e()],1),_c('v-data-table',{staticClass:"app-table",attrs:{"headers":_vm.logList.headers,"items":_vm.logList.data,"items-per-page":25,"disable-sort":true,"loading":_vm.logList.loading,"no-data-text":"","loading-text":"Carregando dados...","hide-default-footer":"","show-select":""},scopedSlots:_vm._u([{key:"item.message",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(item.title,70, "...", true))+" ")]}},{key:"item.stack",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"color":"warning","icon":"","large":""}},[_c('v-icon',[_vm._v("mdi-alert-circle")])],1)],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.description)}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.description),expression:"item.description",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],attrs:{"color":"primary","icon":""}},[_c('v-icon',[_vm._v("mdi-clipboard-text-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Copiar conteúdo")])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("defaultDate")(item.created_at,"DD/MM/YYYY HH:mm"))+" ")]}}],null,true),model:{value:(_vm.logList.selectedLogs),callback:function ($$v) {_vm.$set(_vm.logList, "selectedLogs", $$v)},expression:"logList.selectedLogs"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }