import httpClient from './http-client';

const list = (filter: any): Promise<any> => {
    const params = {
        query: filter
    };

    return httpClient.invoke('log', 'list', params);
};

const update = (logId: string, data: any): Promise<any> => {
    const params = {
        query: {
            logId: logId
        },
        data: data
    };

    return httpClient.invoke('log', 'update', params)
};

const markAsSolved = (logIds: string[]): Promise<any> => {
    const params = {
        query: {
            ids: logIds
        }
    };

    return httpClient.invoke('log', 'markAsSolved', params)
};

const remove = (logId: string): Promise<any> => {
    const params = {
        query: {
            logId: logId
        }
    };

    return httpClient.invoke('log', 'remove', params)
};

export default {
    list,
    update,
    markAsSolved,
    remove
}