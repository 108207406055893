<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5"> Logs </span>
      </v-col>
    </v-row>
    <v-form @submit.prevent="list(true)">
      <v-row>
        <v-col :cols="12">
          <v-card>
            <v-card-title class="card-title">
              <v-icon class="mr-1">mdi-magnify</v-icon>
              Filtro</v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Texto"
                    v-model="logList.filter.text"
                    v-debounce:300ms="list"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-card-text>
            <Paginator
              v-bind.sync="logList.pagingOptions"
              @page="pageSelected"
              :showRefresh="true"
              @refresh="list(true)"
            ></Paginator>

            <ButtonBar>
              <v-btn
                type="button"
                color="primary"
                :loading="solveButton.loading"
                :disabled="!logList.selectedLogs.length"
                @click="markAsSolved"
                v-if="logList.data.length"
                >Marcar como resolvido</v-btn
              >
            </ButtonBar>

            <v-data-table
              :headers="logList.headers"
              :items="logList.data"
              v-model="logList.selectedLogs"
              :items-per-page="25"
              :disable-sort="true"
              :loading="logList.loading"
              no-data-text=""
              loading-text="Carregando dados..."
              class="app-table"
              hide-default-footer
              show-select
            >
              <template v-slot:[`item.message`]="{ item }">
                {{ item.title | truncate(70, "...", true) }}
              </template>

              <template v-slot:[`item.stack`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-btn color="warning" icon large>
                        <v-icon>mdi-alert-circle</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span v-html="item.description"></span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-btn
                        color="primary"
                        icon
                        v-clipboard:copy="item.description"
                        v-clipboard:success="onCopy"
                      >
                        <v-icon>mdi-clipboard-text-outline</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span>Copiar conteúdo</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | defaultDate("DD/MM/YYYY HH:mm") }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import logApi from "@/common/api/log.api";
import Paginator from "@/components/layout/Paginator";
import ButtonBar from "@/components/layout/ButtonBar";
import toaster from "@/common/util/toaster";

export default {
  name: "LogList",

  components: { ButtonBar, Paginator },

  data: () => ({
    logList: {
      filter: {},
      headers: [
        {
          text: "Detalhe",
          value: "stack",
          align: "center",
          width: "120px",
        },
        { text: "Mensagem", value: "message" },
        { text: "Usuário", value: "user_name" },
        { text: "Data do erro", value: "created_at" },
      ],
      data: [],
      selectedLogs: [],
      loading: false,
      pagingOptions: {
        currentPage: 1,
      },
    },
    solveButton: {
      loading: false,
    },
  }),

  created() {
    this.list();
  },

  methods: {
    onCopy() {
      toaster.show("Texto copiado!");
    },

    pageSelected(page) {
      this.logList.pagingOptions.currentPage = page;
      this.list();
    },

    list(resetPage) {
      if (resetPage) {
        this.logList.pagingOptions.currentPage = 1;
      }

      this.logList.filter.page = this.logList.pagingOptions.currentPage;

      this.logList.loading = true;
      logApi.list(this.logList.filter).then((result) => {
        this.logList.loading = false;
        this.logList.data = result.data.data;
        this.logList.pagingOptions = result.data.pagingOptions;
      });
    },

    markAsSolved() {
      let logIds = this.logList.selectedLogs.map((log) => log.id);

      if (logIds.length) {
        this.solveButton.loading = true;
        logApi
          .markAsSolved(logIds.join(","))
          .then(() => {
            this.solveButton.loading = false;
            this.list(true);
            this.logList.selectedLogs = [];
          })
          .catch(() => (this.solveButton.loading = false));
      }
    },
  },
};
</script>
